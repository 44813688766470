import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import style from "./project.module.css";
import ImageDisplay from "../Components/ImageDisplay";
import Mosaic from "../Components/Mosaic";
import NavButton from "../Components/NavButton";
import CardWrapper from "../Components/CardWrapper";
import ReactMarkdown from "react-markdown";

import { Navigate } from "react-router-dom";

import workData from "../data/projects.json";

const usePageMeta = (data, id) => {
  const defaultTitle = data.meta_title || data.title;
  const defaultDesc = data.meta_description || data.description;
  const defaultTags = data.meta_tags;
  const projectId = id;

  useEffect(() => {
    document.title = defaultTitle;
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", defaultDesc);
    document
      .querySelector("meta[name='keywords']")
      .setAttribute("content", defaultTags);
    document
      .querySelector("link[rel='canonical']")
      .setAttribute('href', `https://ericknock.dev/projects/${projectId}`);
  }, [defaultDesc, defaultTags, defaultTitle, projectId]);
};

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

const ProjectPage = () => {
  let { projectId } = useParams();
  const projectData = workData.projects[projectId];

  usePageMeta(projectData, projectId);

  if (!projectData) return <Navigate to="/projects" replace />;

  const keys = Object.keys(workData.projects);
  const index = keys.indexOf(projectId);
  const prevProj = keys[(index - 1 + keys.length) % keys.length];
  const nextProj = keys[(index + 1 + keys.length) % keys.length];

  let nav = <hr></hr>;
  if (projectData.link) {
    nav = (
      <div className={style.mHolder}>
        <Mosaic use_cubic={true}>
          <div className={style.cHolder}>
            <CardWrapper width="120px">
              <NavButton name="Visit" link={projectData.link} />
            </CardWrapper>
          </div>
        </Mosaic>
      </div>
    );
  }

  return (
    <div className={style.pContainer}>
      <div className={style.imgContainer}>
        <ImageDisplay caption={true} imgs={projectData.images} />
      </div>
      <div className={style.notImgContainer}>
        <div className={style.content}>
          <div
            className={"hideScroll " + style.title}
            style={{ width: "100%", overflow: "auto" }}
          >
            <h1 className={style.title}>{projectData.title}</h1>
          </div>
          <div className={style.date}>
            <p>{projectData.dates}</p>
          </div>
          {nav}
          <div className={style.main}>
            <ReactMarkdown components={{ a: LinkRenderer }}>
              {projectData.desc}
            </ReactMarkdown>
          </div>
          <div className={style.mHolder}>
            <Mosaic use_cubic={true}>
              <div className={style.cHolder}>
                <CardWrapper width="120px">
                  <NavButton name="Previous" link={`/projects/${prevProj}`} />
                </CardWrapper>
                <CardWrapper width="120px">
                  <NavButton name="Next" link={`/projects/${nextProj}`} />
                </CardWrapper>
              </div>
            </Mosaic>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
